import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import LoremIpsum from "./content/LoremIpsum";
import Koeaika from "./content/Koeaika";
import Monimuotoisuuslupaus from "./content/Monimuotoisuuslupaus";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route index element={<LoremIpsum />} />
        <Route path="koeaika" element={<Koeaika />} />
        <Route path="monimuotoisuuslupaus" element={<Monimuotoisuuslupaus />} />
      </Route>
      <Route path="*" element={<p>There's nothing here!</p>} />
    </Routes>
  </BrowserRouter>
);

export default App;
