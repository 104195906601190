import styled from "styled-components";

const BlockQuote = styled.blockquote`
  color: var(--color-${(props) => props.color});
  font-weight: bold;
  border-left: 2px solid var(--color-${(props) => props.color || "white"});
  margin-left: 0;
  padding-left: 2.5rem;
`;

export default BlockQuote;
