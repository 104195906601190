import styled from "styled-components";

const Team = styled.p`
  column-count: 2;

  @media (max-width: 680px) {
    column-count: 1;
    text-align: center;
  }
`;

export default Team;
