import styled from "styled-components";

const FrameCard = styled.div`
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--color-light-black);
`;

const FrameTitle = styled.p`
  margin: 1rem;
  text-align: center;
  font-size: 1.3rem;
  color: var(--color-white);
`;

const FrameWrapper = styled.div`
  padding-bottom: 56.25%;
  left: 50%;
  right: 50%;
  margin-left: -50%;
  margin-right: -50%;
  max-width: 100%;
  width: 100%;
  position: relative;
  height: 0;
`;

const FullWidthFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  border: none;
`;

const YoutubeEmbed = (props) => {
  const src = `https://www.youtube.com/embed/${props.src}`;

  return (
    <FrameCard>
      <FrameWrapper>
        <FullWidthFrame
          src={src}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></FullWidthFrame>
      </FrameWrapper>
      <FrameTitle>{props.title}</FrameTitle>
    </FrameCard>
  );
};

export default YoutubeEmbed;
