import styled from "styled-components";
import Hash from "./Hash";

const Wrapper = styled.div`
  margin: 4rem 0;
  text-align: center;
`;

const Heading = styled.h1`
  font-size: calc(2rem + 8vw);
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 0;

  ${(props) =>
    props.small && `font-size: calc(1rem + 2vw); margin-bottom: 2rem;`}
  ${(props) =>
    props.full &&
    `
      width: 100vw;
      max-width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    `}
`;

const Subtitle = styled.p`
  font-size: 1rem;
  margin: 0;

  @media (max-width: 680px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

const Title = (props) => (
  <Wrapper>
    <Heading small={props.small} full={props.full}>
      {props.hash && <Hash />}
      {props.heading}
    </Heading>
    <Subtitle>{props.subheading}</Subtitle>
  </Wrapper>
);

export default Title;
