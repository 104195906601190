import styled from "styled-components";

const Lede = styled.p`
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 680px) {
    font-size: ${(props) => (props.small ? "1rem;" : "1.3rem")};
  }
`;

export default Lede;
