import { useEffect } from "react";
import styled from "styled-components";

import GlobalStyle from "../themes/LoremIpsum.js";
import Section from "../components/Section";
import Button from "../components/Button.js";

const Content = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 8rem;
`;

const LoremIpsum = () => {
  useEffect(() => {
    document.title = "Duunitori | Grandone 2022";
  }, []);

  return (
    <main>
      <GlobalStyle />
      <Section color="black">
        <Content>
          <Button to="/koeaika">Koeaika</Button>
          <Button to="/monimuotoisuuslupaus">Monimuotoisuuslupaus</Button>
        </Content>
      </Section>
    </main>
  );
};

export default LoremIpsum;
