import { useEffect } from "react";

import GlobalStyle from "../themes/Monimuotoisuuslupaus.js";
import Title from "../components/Title";
import Quote from "../components/Quote";
import WideImage from "../components/WideImage";
import BlockQuote from "../components/BlockQuote";
import NarrowImage from "../components/NarrowImage";
import Lede from "../components/Lede";
import Section from "../components/Section";
import KeyFigures from "../components/KeyFigures";
import Header from "../components/Header";
import Button from "../components/Button";
import Quotes from "../components/Quotes";
import Highlight from "../components/Highlight";

import header from "../imgs/mml-header.png";
import logos from "../imgs/mml-logos.png";
import media11 from "../imgs/mml-media-11.png";
import media12 from "../imgs/mml-media-12.png";
import media13 from "../imgs/mml-media-13.png";
import media14 from "../imgs/mml-media-14.png";
import media21 from "../imgs/mml-media-21.png";
import media22 from "../imgs/mml-media-22.png";
import media23 from "../imgs/mml-media-23.png";
import media24 from "../imgs/mml-media-24.png";
import media25 from "../imgs/mml-media-25.png";
import rg1 from "../imgs/rg1.png";
import rg2 from "../imgs/rg2.png";
import montage from "../imgs/mml-montage.png";
import vilma from "../imgs/vilma.png";
import screenshots from "../imgs/mml-screenshots.png";
import examples from "../imgs/mml-examples.png";
import Boxes from "../components/Boxes.js";

const Monimuotoisuuslupaus = () => {
  useEffect(() => {
    document.title = "Monimuotoisuuslupaus | Duunitori | Grandone 2022";
  }, []);

  return (
    <main>
      <GlobalStyle />

      <Header>
        <p>Suomen suurin työnhakupalvelu ja lähes tuhat työnantajaa</p>
        <Button href="https://duunitori.fi/monimuotoisuuslupaus">
          Monimuotoisuuslupaus
        </Button>
      </Header>

      <Title
        heading="Monimuotoisuuslupaus"
        subheading="Kohti reilumpaa rekrytointia ja yhdenvertaisempia työpaikkoja"
        small
        full
      ></Title>

      <Section overflow>
        <WideImage src={header} negMargin />
      </Section>

      <Section>
        <Lede>
          Suomi tarvitsee monimuotoisia tiimejä, ja muutos lähtee
          rekrytoinnista. Monimuotoisuus lisää työyhteisön tasa-arvoa,
          työhyvinvointia ja luovuutta – sekä auttaa tekemään parempia tuloksia.
          Työnantajilla ei ole varaa hukata ihmisten potentiaalia, joten on
          tärkeää löytää monenlaisia osaajia.
        </Lede>

        <p>
          Siksi Duunitori lanseerasi monimuotoisuuslupauksen, jonka työnantaja
          voi lisätä työpaikkailmoituksiinsa. Se on tapa ilmaista
          työnhakijoille, että organisaatio arvostaa henkilöstön
          monimuotoisuutta ja tekee töitä sen eteen. Lupaus sitouttaa
          työnantajia kehittämään inklusiivisempia työyhteisöä ja houkuttelemaan
          ihmisiä erilaisista taustoista hakemaan avoimia paikkoja.
        </p>
      </Section>
      <Section>
        <NarrowImage src={screenshots} />
      </Section>
      <Section>
        <p>
          Monen muun tahon tapaan me Duunitorilla olemme pohtineet viime
          vuosina, miten monimuotoisuutta voisi edistää rekrytoinnin keinoin.
          Aiemmin olemme lanseeranneet esimerkiksi sukupuolineutraalit
          ammattinimikkeet.
        </p>

        <Lede>
          Idea työnhakupalvelun uudesta konkreettisesta askeleesta syntyi
          yhteistyössä, kun 16-vuotias Vilma Hoviniemi valtasi Duunitorin
          toimitusjohtajan paikan osana Plan Internationalin
          #GirlsTakeover-tempausta.
        </Lede>

        <p>
          Duunitori, Plan ja Hoviniemi työstivät aihetta yhdessä kuukausien
          ajan, ja lopulta Vilma päätti monimuotoisuuslupauksesta. Lupaus syntyi
          Työterveyslaitoksen ja THL:n esimerkin pohjalta yhdessä rekrytoinnin
          asiantuntijoiden ja Planin tasa-arvoasiantuntijoiden kanssa.
          Työnantaja voi lisätä lupaustekstin työpaikkailmoitukseen suomeksi tai
          englanniksi.
        </p>

        <p>Monimuotoisuuslupaus:</p>

        <BlockQuote color="highlight">
          Arvostamme työyhteisön monimuotoisuutta ja teemme töitä
          yhdenvertaisuuden eteen. Toivomme hakijoita erilaisista taustoista,
          kuten eri-ikäisiä, eri sukupuolia olevia, vammaisia ihmisiä sekä eri
          kieli-, kulttuuri- tai muihin väestöryhmiin kuuluvia.
        </BlockQuote>
      </Section>

      <Section>
        <WideImage src={vilma} />
      </Section>

      <Section>
        <Lede>15 työnantajan etujoukko tarttui haasteeseen.</Lede>

        <p>
          DNA lisäsi ensimmäisenä monimuotoisuuslupauksen kaikkiin
          työpaikkailmoituksiinsa. Saimme etujoukkoon myös muita merkittäviä
          työnantajia: Aalto-yliopisto, Accenture, Bolt.Works, Diakonissalaitos,
          EY, Gigantti, Helsingin kaupunki, IKEA Suomi, Keskinäinen
          työeläkevakuutusyhtiö Varma, Lindström, Mehiläinen, MPS Career ja
          Solita tekivät lupauksen. Ja tietysti myös Duunitori itse oli mukana.
        </p>

        <p>
          Ensimmäisten kuukausien aikana monimuotoisuuslupauksen on tehnyt jo
          lähes tuhat eri työnantajaa, ja joukko kasvaa jatkuvasti. Näiden
          edelläkävijöiden työpaikkailmoitukset ovat keränneet yli miljoona
          lukukertaa, ja kymmenet tuhannet ovat hakeneet työpaikkoja. Valtava
          joukko ihmisiä työskentelee näissä työpaikoissa, jotka sitoutuvat
          yhdenvertaisuuden edistämiseen.
        </p>
      </Section>
      <Section>
        <NarrowImage src={logos} />
      </Section>
      <Section>
        <Quote
          source="Tuuli Nummelin, vastuullisuuspäällikkö, DNA"
          color="white"
        >
          <p>
            Lähdimme innolla mukaan osaksi Duunitorin haastetta, sillä se tukee
            erinomaisesti Omanlainen, yhdenvertainen DNA -viestiämme ja
            tavoitteitamme. Me DNA:lla olemme esimerkiksi ymmärtäneet, että
            IT-alalla naisten määrä etenkin johtotehtävissä on liian pieni. DNA
            onkin nostanut ensimmäiseksi tavoitteekseen sukupuolten tasa-arvon
            edistämisen.
          </p>
        </Quote>
      </Section>

      <Section>
        <NarrowImage src={examples} />
      </Section>

      <Section>
        <Lede>
          <Highlight>Monimuotoisuuslupaus</Highlight> julkistettiin
          marraskuusssa 2021 loppuunmyydyssä Rekrygaalassa, jonka avasi
          Duunitorin 16-vuotias #GirlsTakeover-valtaaja Vilma Hoviniemi upealla
          puheellaan.
        </Lede>
      </Section>

      <Section>
        <WideImage src={rg1} />
      </Section>

      <Section color="black">
        <p>
          Hetki oli unohtumaton: yleisö osoitti pitkään suosiota hurraten ja
          seisten. Moni osallistuja tuli illan aikana kiittämään Vilmaa ja jakoi
          hänen sanomaansa sosiaalisessa mediassa.
        </p>

        <p>
          Tärkeä asia tavoitti ison joukon päättäjiä, sillä gaalaan osallistui
          200 johtajaa ja rekrytoinnin asiantuntijaa, joista moni edustaa suuria
          työnantajia.
        </p>
      </Section>

      <Section>
        <Quote source="Vilma Hoviniemi, 16, Rekrygaalassa" color="white">
          <p>
            Kun ajattelen tulevaisuuttani ja siellä odottavaa työelämää, haluan
            nähdä sen valoisana ja luottaa kehitykseen. En halua kokea tai nähdä
            syrjintää, epätasa-arvoa. Haluan astua työelämään, jossa minun ja
            kaikkien osaamista ja panosta arvostetaan.
          </p>
          <p>
            Arvojen on siis oltava kunnossa. Nämä asiat ovat tärkeitä: 83
            prosenttia meistä uuden sukupolven nuorista kokee työnantajan ja
            yrityksen arvot, esimerkiksi monimuotoisuuteen sitoutumisen,
            tärkeänä. Vastuu paremman työelämän rakentamisesta on siis pitkälti
            työnantajilla.
          </p>
        </Quote>
      </Section>

      <Section>
        <WideImage src={rg2} />
      </Section>

      <Section mobileCenter>
        <Lede>
          Monimuotoisuuden sanoma levisi ja aktivoi työnantajat mukaan.
        </Lede>
        <KeyFigures
          contents={[
            ["992", "työnantajaa lisäsi lupauksen työpaikkailmoituksiinsa"],
            ["8 045", "työpaikkailmoitusta näiltä työnantajilta"],
            ["1 103 000", "työpaikkailmoitusten lukukertaa"],
            [
              "180 000",
              "lukukertaa monimuotoisuutta käsitteleville artikkeleille",
            ],
            [
              "188 000",
              "sivulatausta lupauksen nostolle Duunitorin etusivulla",
            ],
            [
              "99 700",
              "työntekijää, joiden työnantaja on lisännyt monimuotoisuuslupauksen profiiliinsa",
            ],
          ]}
        />

        <small>
          * Työpaikkailmoitusten tulokset ovat reilun kahden kuukauden ajalta
          4.11.2021–14.1.2022. Kyseessä ei ole ohimenevä kampanja, vaan pysyvä
          ominaisuus, joten tulokset ovat kesken ja tulevat vielä kasvamaan.
          Monimuotoisuuslupaus-nosto näkyi Duunitorin etusivulla 9.–22.11.2021.
        </small>
      </Section>

      <Section extraPaddingTop>
        <WideImage src={montage} />
      </Section>

      <Section>
        <Lede>
          Monimuotoisuuslupaus näkyi monin tavoin Duunitorin työnhakupalvelussa,
          markkinoinnissa ja viestinnässä. Työnhakukoneen etusivu julisti
          ”Kaikki työpaikat, ihan kaikille”.
        </Lede>
        <p>
          Lupaus ei yksinään riitä, vaan tarvitsee rinnalleen muita tekoja.
          Niistä kerroimme työnantajille ja kaikille työelämästä kiinnostuneille
          julkaisemalla lukuisia artikkeleita niin ennen kuin jälkeen lupauksen
          julkistamisen. Kerroimme niin aliedustettuihin ryhmiin kuuluvien
          henkilötarinoita kuin asiantuntijoiden konkreettisia vinkkejäkin
          diversiteetin ja inklusiivisuuden lisäämiseksi.
        </p>

        <p>
          Duunitorin työelämämedian sisällöt käsittelivät muun muassa työelämään
          astumista kehitysvammaisena nuorena, naisten asemaa pelialalla,
          ikäsyrjintää, työnhaun rasismia, suomalaisen työelämän
          kielivaatimuksia, seksuaali- ja sukupuolivähemmistöjen asemaa
          työpaikoilla sekä palkkatasa-arvoa. Rekrygaalaa seurasi
          Rekryjatkot-webinaari, jossa pohdittiin monimuotoisuuden edistämistä
          rekrytoinnin keinoin.
        </p>

        <p>
          Lupauksen tehneet työnantajat, organisaatioiden työntekijät, aiheen
          asiantuntijat sekä muut sidosryhmät viestivät lupauksesta
          aktiivisesti. Monimuotoisuuslupaus ja artikkelit monimuotoisuudesta
          herättivät runsaasti reaktioita ja kiinnostavaa keskustelua
          sosiaalisessa mediassa. Myös media kiinnostui teemasta ja
          konkreettisesta teosta.
        </p>
      </Section>

      <Section>
        <Boxes
          contents={[
            {
              src: media11,
              wide: true,
              title:
                "Duunitori haluaa reilumpaa rekrytointia – työonantajat voivat nyt tehdä palvelussa monimuotoisuuslupauksen",
              media: "Markkinointiuutiset",
              mobile: true,
            },
            {
              src: media12,
              wide: true,
              title:
                "Valkeakoskelainen Vilma Hoviniemi, 15, astuu päiväksi Duunitorin johtoon – Toivoo, ettei koko tempausta tarvittaisi: ”Haluan uskoa, että tulevaisuudessa tyttöjen ei tarvitse enää ajatella, riitänkö tai pystynkö”",
              media: "Valkeakosken sanomat",
              mobile: true,
            },
            {
              src: media13,
              wide: true,
              title:
                "Ennakkoluulottomuutta ja emojien käyttöä – 12 syytä, miksi miestenkin kannattaa oppia teinitytöiltä oikeaa elämänasennetta",
              media: "Me Naiset",
              mobile: true,
            },
            {
              src: media14,
              wide: true,
              title:
                "Monimuotoisuus, palkka-avoimuus, yhdenvertaisuus ja tasa-arvo",
              media: "Integrata",
            },
            {
              src: media21,
              title: "DNA: Monimuotoisuus mukana arjen työssä",
              media: "Palta",
            },
            {
              src: media22,
              title:
                "Tytöt vaativat lisää tietoa ja tasa-aroa #girlstakeoverissa",
              media: "PLAN",
            },
            {
              src: media23,
              title: "Lupaus yhdenvertaisesta rekrytoinnista on hyvä alku",
              media: "Aamulehti",
              mobile: true,
            },
            {
              src: media24,
              title: "Monimuotoisuuslupaus edistää reilumpaa rekrytointia",
              media: "Tie työelämään",
              mobile: true,
            },
            {
              src: media25,
              title:
                "DNA ensimmäisenä yrityksenä mukaan monimuotoisuuslupaukseen",
              media: "DNA",
            },
          ]}
        />
      </Section>

      <Section>
        <Lede>
          Asiantuntijat, #GirlsTakeoverin nuoret ja aliedustettujen ryhmien
          edustajat pitivät lupausta tärkeänä askeleena.
        </Lede>

        <Quotes
          contents={[
            {
              text: "On upea juttu, että Suomen suurin työnhakupalvelu lanseeraa työpaikkailmoitusten monimuotoisuuslupauksen. Se, että lupaus on tarjolla ilmoitusjärjestelmässä, voi tuupata työnantajia ja rekrytoijia oikeaan suuntaan ja herättää pohtimaan oman organisaation monimuotoisuuden tilaa.",
              source:
                "Shadia Rask, tutkimuspäällikkö, Terveyden ja hyvinvoinnin laitos THL",
            },
            {
              text: "Wau, miten ihana juttu tuo teidän uusi moninaisuuslupaus.  Uskon, että sillä tulee olemaan positiivinen vaikutus vähemmistöryhmien työllistymiseen ja siihen, että työelämässä alkaa kuulua vähemmistöjen ääni. Näin esimerkiksi minä uskaltaisin helpommin hakea työpaikkaa, kun ei tarvitse pelätä, miten työyhteisössä otetaan vähemmistöryhmät vastaan.",
              source: "",
              narrow: true,
            },
            {
              text: "Monimuotoisuuslupaus on todella hyvä idea. On tärkeää, että työnantajat asettuvat monimuotoisuuden puolelle julkisesti ja toimivat sitten niin kuin lupaavat.",
              source: "Tuula Haatainen, työministeri",
              narrow: true,
            },
          ]}
        />
      </Section>

      <Section>
        <Lede>Duunitori</Lede>
        <p>
          <a href="https://duunitori.fi/monimuotoisuuslupaus">
            duunitori.fi/monimuotoisuuslupaus
          </a>
        </p>
      </Section>
    </main>
  );
};

export default Monimuotoisuuslupaus;
