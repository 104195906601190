import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --color-bg: #222222;
    --color-black: #222222;
    --color-light-black: #333333;
    --color-text: #ffffff;
    --color-white: #ffffff;
    --color-highlight: #01a69c;
  }
`;

export default GlobalStyle;
