import styled from "styled-components";

import Quote from "./Quote";

const Wrapper = styled.div`
  overflow: hidden;
`;

const Quotes = (props) => (
  <Wrapper>
    {props.contents.map(({ text, source, narrow }) => (
      <Quote narrow={narrow} source={source} color="white">
        <p>{text}</p>
      </Quote>
    ))}
  </Wrapper>
);

export default Quotes;
