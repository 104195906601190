import styled from "styled-components";

const BG = styled.section`
  padding: 3rem;
  ${(props) => props.color && `background-color: var(--color-${props.color});`}
  ${(props) => props.extraPaddingBottom && `padding-bottom: 7rem;`}
  ${(props) => props.extraPaddingTop && `padding-top: 7rem;`}
  overflow: ${(props) => (props.overflow ? "visible" : "hidden")};
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: var(--content-width);
  ${(props) =>
    props.mobileCenter &&
    `
    @media (max-width: 680px) {
      text-align: center;
    }
  `}
`;

const Section = (props) => (
  <BG {...props}>
    <Content {...props}>{props.children}</Content>
  </BG>
);

export default Section;
