import styled from "styled-components";

import { Link } from "react-router-dom";

import arrow from "../imgs/arrow.svg";

const Arrow = styled.img`
  height: 1rem;
  margin-left: 1rem;
`;

const styles = `
  background-color: var(--color-white);
  color: var(--color-bg);
  text-decoration: none;
  padding: 0.6em 1.2em;
  border-radius: 3em;
  display: inline-flex;
  align-items: center;
`;

const ExternalLink = styled.a`
  ${styles}
`;
const InternalLink = styled(Link)`
  ${styles}
`;

const Button = (props) => {
  const El = props.to ? InternalLink : ExternalLink;
  return (
    <El {...props} target={props.to ? false : "_blank"}>
      {props.children}
      <Arrow src={arrow} />
    </El>
  );
};

export default Button;
