import styled from "styled-components";

import Comment from "./Comment";

const Container = styled.div`
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-color {
    var(--color-bg)
  }

  position: relative;
  left: 50%;
  right: 50%;
  margin: 1rem -50vw;
  max-width: 100vw;
  width: 100vw;

  display: flex;
  justify-content: stretch;
`;

const ElementWrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 1 0 auto;
  ${(props) => props.narrow && "flex: 0 0 16rem"};
`;

const Title = styled.h2`
  font-size: 1.3rem;
  text-align: center;
  margin-top: 1.5rem;
`;

const Reel = (props) => {
  return (
    <Container>
      {props?.contents?.map((c, i) => (
        <>
          <ElementWrapper>
            <Comment bg="light-black" color="white">
              {c}
            </Comment>
          </ElementWrapper>
          {props?.title && i === 1 && (
            <ElementWrapper narrow>
              <Title>{props.title}</Title>
            </ElementWrapper>
          )}
        </>
      ))}
    </Container>
  );
};

export default Reel;
