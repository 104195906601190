import styled from "styled-components";

const List = styled.dl`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-column-gap: 1rem;

  @media (max-width: 755px) {
    text-align: center;
    grid-template-columns: 1fr;
  }
`;
const Title = styled.dt`
  font-size: 2.5rem;
  font-weight: bold;
`;
const Description = styled.dd`
  margin-left: 0;
  margin-bottom: 2.5rem;
`;

const KeyFigures = (props) => (
  <List>
    {props?.contents?.map(([content, value]) => {
      return (
        <div>
          <Title>{content}</Title>
          <Description>{value}</Description>
        </div>
      );
    })}
  </List>
);

export default KeyFigures;
