import styled from "styled-components";

import quote from "../imgs/quote.svg";
import quoteWhite from "../imgs/quote-white.svg";

const CommentWrapper = styled.p`
  background-color: var(--truquoise);
  color: var(--color-bg);
  border-radius: 20px;
  padding: 1rem 1.5rem 1rem 3rem;
  font-size: 0.8rem;
  background-color: var(
    --color-${(props) => (props.bg && props.bg) || "highlight"}
  );
  color: var(--color-${(props) => (props.color && props.color) || "bg"});
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  max-width: 30em;

  ${(props) =>
    !props.positioned &&
    `
      height: 100%;
    `}

  ${(props) =>
    props.positioned &&
    `
    &:before {
      content: "";
        ${
          props.color === "white"
            ? `background-image: url(${quoteWhite})`
            : `background-image: url(${quote})`
        };
      width: 1rem;
      height: 1rem;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 0.7em;
      top: 0.6em;

      font-size: 2em;
      font-weight: bold;
    }
  `}

  ${(props) =>
    props.positioned &&
    `
    position: absolute;
    width: 15em;

    &:nth-of-type(1) {
        border-bottom-right-radius: 0;
        top:
        50%;
        right: calc(100% + 20px);
      }

      &:nth-of-type(2) {
        border-bottom-left-radius: 0;
        top: 15%;
        left: calc(100% + 20px);
      }

      @media (max-width: 900px) {
        width: auto;
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
        display: inline-block;
        margin-left: 2em;
        margin-right: 2em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        &:nth-of-type(1) {
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 20px;
          top: unset;
          right: unset;
          margin-bottom: 2em;
        }

        &:nth-of-type(2) {
          float: right;
          border-top-right-radius: 0;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          top: unset;
          left: unset;
        }
      }
    `}
`;

const C = styled.span`
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  &:before {
    content: "";
    ${(props) =>
      props.color === "white"
        ? `background-image: url(${quoteWhite})`
        : `background-image: url(${quote})`};
    width: 1rem;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -1em;
    top: 0;

    font-size: 2em;
    font-weight: bold;
  }
`;

const Comment = (props) => (
  <CommentWrapper {...props}>
    {props.positioned ? props.children : <C {...props}>{props.children}</C>}
  </CommentWrapper>
);

export default Comment;
