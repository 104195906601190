import styled from "styled-components";

const YoutubeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(400px, 100%), 1fr));
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  padding: 3rem 1.5rem 0;

  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
`;

export default YoutubeGrid;
