import styled from "styled-components";

const WideImageStyles = styled.img`
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: calc(var(--full-width-vw) / -2);
  margin-right: calc(var(--full-width-vw) / -2);
  max-width: var(--full-width-vw);
  width: var(--full-width-vw);

  ${(props) => props.negMargin && "margin-bottom: calc(-16vw); z-index: -1"}
`;

const WideImage = (props) => (
  <WideImageStyles
    {...props}
    src={props.src}
    {...(props.mobileSrc && {
      srcSet: `
        ${props.src} 2500w,
        ${props.mobileSrc} 680w
      `,
      sizes: `
        (max-width: 680px) 100px,
        2500px
      `,
    })}
  />
);

export default WideImage;
