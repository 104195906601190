import styled from "styled-components";

const VideoEl = styled.video`
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: calc(var(--full-width-vw) / -2);
  margin-right: calc(var(--full-width-vw) / -2);
  max-width: var(--full-width-vw);
  width: var(--full-width-vw);
  height: auto;
  display: block;
`;

const Video = (props) => (
  <VideoEl src={props.src} poster={props.poster} controls loop></VideoEl>
);

export default Video;
