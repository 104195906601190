import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --color-bg: #001e43;
    --color-white: #ffffff;
    --color-text: #ffffff;
    --color-highlight: #01a69c;
  }
`;

export default GlobalStyle;
