import styled from "styled-components";

import logo from "../imgs/duunitori.svg";

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 2rem;
  align-items: center;

  @media (max-width: 493px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  flex: 0 0 150px;
  width: 150px;
  height: auto;
  @media (max-width: 680px) {
    width: 100px;
    flex-basis: 60px;
  }
`;

const Content = styled.div`
  text-align: right;
  @media (max-width: 493px) {
    text-align: center;
  }

  p {
    margin-top: 0;
  }
`;

const Header = (props) => (
  <Wrapper>
    <Logo src={logo} />
    <Content>{props.children}</Content>
  </Wrapper>
);

export default Header;
