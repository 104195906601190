import styled from "styled-components";

import Comment from "./Comment";

const Image = styled.img`
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
`;

const NarrowImage = (props) => {
  return (
    <Wrapper>
      {props?.comments?.[0] && (
        <Comment positioned>{props.comments[0]}</Comment>
      )}

      <Image
        {...props}
        src={props.src}
        {...(props.mobileSrc && {
          srcSet: `
          ${props.src} 2500w,
          ${props.mobileSrc} 680w
        `,
          sizes: `
          (max-width: 680px) 100px,
          2500px
        `,
        })}
      />

      {props?.comments?.[1] && (
        <Comment positioned>{props.comments[1]}</Comment>
      )}
    </Wrapper>
  );
};

export default NarrowImage;
