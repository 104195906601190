import styled from "styled-components";

import Check from "../imgs/check.png";

const Checklist = styled.ul`
  list-style: none;
  padding-left: 2.6em;

  li {
    position: relative;
    padding: 0.4em 0;

    &:before {
      content: "";
      background-image: url(${Check});
      background-repeat: none;
      background-size: cover;
      width: 1.5em;
      height: 1.5em;
      position: absolute;
      left: -2.5em;
      top: 0.3em;
    }
  }
`;

export default Checklist;
