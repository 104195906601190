import { useEffect } from "react";

import GlobalStyle from "../themes/Koeaika.js";
import Header from "../components/Header";
import Reel from "../components/Reel";
import KeyFigures from "../components/KeyFigures";
import Title from "../components/Title";
import WideImage from "../components/WideImage";
import NarrowImage from "../components/NarrowImage";
import Video from "../components/Video";
import Lede from "../components/Lede";
import Section from "../components/Section";
import Checklist from "../components/Checklist";
import Hash from "../components/Hash";
import Quotes from "../components/Quotes";
import Team from "../components/Team";
import Button from "../components/Button";
import Boxes from "../components/Boxes";
import YoutubeGrid from "../components/YoutubeGrid";

import reel1 from "../imgs/ka-reel-1.png";
import reel2 from "../imgs/ka-reel-2.png";
import logos from "../imgs/ka-logos.png";
import logosMobile from "../imgs/ka-logos-mobile.png";
import feedback from "../imgs/ka-feedback.png";
import feedbackMobile from "../imgs/ka-feedback-mobile.png";
import montage from "../imgs/ka-montage.png";
import highlights from "../imgs/ka-highlights.png";
import media11 from "../imgs/ka-media-11.png";
import media12 from "../imgs/ka-media-12.png";
import media13 from "../imgs/ka-media-13.png";
import media14 from "../imgs/ka-media-14.png";
import media21 from "../imgs/ka-media-21.png";
import media22 from "../imgs/ka-media-22.png";
import media23 from "../imgs/ka-media-23.png";
import media24 from "../imgs/ka-media-24.png";
import media25 from "../imgs/ka-media-25.png";
import media31 from "../imgs/ka-media-31.png";
import media32 from "../imgs/ka-media-32.png";
import media33 from "../imgs/ka-media-33.png";
import media34 from "../imgs/ka-media-34.png";
import thumbnail from "../imgs/ka-thumbnail.png";
import YoutubeEmbed from "../components/YoutubeEmbed.js";

const Main = () => {
  useEffect(() => {
    document.title = "#Koeaika | Duunitori | Grandone 2022";
  }, []);

  return (
    <>
      <GlobalStyle />
      <Header>
        <p>Työpsykologi Jaakko Sahimaa kokeili 12 eri työtä</p>
        <Button href="https://koeaika.fi">koeaika.fi</Button>
      </Header>
      <main>
        <Title
          heading="Koeaika"
          subheading="12 kuukauden tutkimusmatka suomalaiseen työelämään"
          hash
        />
        <Section>
          <Video
            src="https://skyhood-duunitori5.s3.eu-central-1.amazonaws.com/media/koeaika.mp4"
            poster={thumbnail}
          />
        </Section>
        <Section>
          <Lede small>
            Joka päivä alkaa jonkun suomalaisen työpäivä. Mutta ymmärrämmekö me
            toistemme työtä? Arvostammeko sitä riittävästi? Miten työelämän
            murros näkyy käytännössä? <Hash />
            Koeaika syntyi vastaamaan näihin kysymyksiin. Sen metodina oli
            tutustua 12 erilaiseen työhön – tekemällä niitä.
          </Lede>

          <p>
            Vuosi sitten organisaatiopsykologi Jaakko Sahimaa aloitti kaikkien
            aikojen tutkimusmatkan, jolla hän vaihtaa vuoden aikana joka
            kuukausi työpaikkaa. Lopputuloksena oli Duunitorin, Jaakon ja
            työnantajien ainutlaatuinen sarja, joka sukelsi miljoonayleisönsä
            kanssa suomalaiseen työelämään.
          </p>
        </Section>
        <Section>
          <NarrowImage
            src={highlights}
            comments={[
              "Ihanaa kun rikot ennakkoluuloja myös konsulttien työstä.",
              "Hieno näyteikkuna moneen duuniin!",
            ]}
          />
        </Section>
        <Section>
          <p>
            Jaakko tutki työpaikkoja konepellin alta: itse erilaisia töitä
            kokeilemalla. Hän työskenteli kuukauden verran muun muassa
            opettajana, konsulttina, päiväkodissa, kauppiaana, rakennusalalla,
            it-hommissa ja asiakaspalvelussa. Vaihtuvissa työrooleissa Jaakko
            palveli esimerkiksi suuryrityksiä, kehitysvammaisia lapsia ja Ärrän
            kantiksia. Työpöydällä oli niin koodia kuin vaippojen
            vaihtamistakin. <Hash />
            Koeaika-vuosi huipentuu helmikuussa 2022 yhteiskunnallisella
            näkökulmalla, kun alkaa Jaakon 12. työ koti-isänä.
          </p>

          <p>
            <Hash />
            Koeajalla syntyi sisältöjä ja mainontaa työnantajista. Vuoden
            kestäneessä kampanjakokonaisuudessa yhdistyvät työelämän
            asiantuntijan näkökulma ja aito kokemus työpaikan arjesta, aina
            kahvihuoneesta sorvin (tai näppäimistön) äärelle. Jaakko raportoi,
            mikä yllätti, mikä motivoi ja kuormittaa kyseisessä tehtävässä sekä
            mikä tekee työstä merkityksellistä. Ja tietysti, miten aina
            asiantuntijatyötä tehnyt psykologi itse pärjäsi vaikkapa
            haalarihommissa tai vuorotyössä.
          </p>
        </Section>
        <Section>
          <WideImage src={reel1} />
          <Reel
            contents={[
              "Tämän päivän harjoittelija on huomisen huippu.",
              "Tärkeämpää kuin kyky puhua on kyky kuunnella.",
              "Työhyvinvointi on jokaisen vastuulla, mutta lähtee liikkeelle johtajien esimerkistä.",
              "Kun työntekijä kohdataan kokonaisena ihmisenä, hän myös antaa kokonaisen panoksen.",
              "Rakennusalan osaajien työ koskettaa meistä jokaista ja mahdollistaa mielekkään arjen.",
              "Asiakas on näissä hommissa päällikkö.",
              "Asiakaspalvelija on yrityksen käyntikortti.",
              "Säkkituolit ja pallomeret eivät tee hyvää työpaikkaa. Ihmiset ja työn sisältö tekevät.",
              "Hyvällä työpaikalla ei ole kahden kerroksen väkeä.",
              "Rekrytoinnin ja hr-alan osaajat eivät edusta ihan kivaa tukitoimintoa, vaan ovat kullanarvoisia organisaatioille ja bisnekselle.",
            ]}
            title="Jokainen työ herätti uusia ajatuksia"
          />
          <WideImage src={reel2} />
        </Section>
        <Section extraPaddingBottom>
          <h2>Tutkimusmatkan tavoitteet</h2>
          <Checklist>
            <li>Selvittää, millaista on muuttuva työarki.</li>
            <li>Herättää kiinnostavaa keskustelua työelämästä.</li>
            <li>
              Kertoa laajalle yleisölle erilaisista aloista ja kasvattaa
              ammattien arvostusta.
            </li>
            <li>
              Markkinoida asiakkaiden työpaikkoja ja kehittää houkuttelevaa
              työnantajakuvaa.
            </li>
          </Checklist>
        </Section>

        <Section extraPaddingBottom>
          <Lede>
            Jaakon kokemuksista kertovat mainosvideot saivat yleisön uppoutumaan
            työpaikkojen yllättäväänkin arkeen.
          </Lede>
          <YoutubeGrid>
            <YoutubeEmbed src="QO4EXZbn9OM" title="Tammikuu 2022" />
            <YoutubeEmbed src="CT9dL9sN6G8" title="Joulukuu 2021" />
            <YoutubeEmbed src="xB2GLLrHCZA" title="Marraskuu 2021" />
            <YoutubeEmbed src="i1gezZH-HVE" title="Lokakuu 2021" />
            <YoutubeEmbed src="NIWrr71mVI8" title="Syyskuu 2021" />
            <YoutubeEmbed src="Rgdo97MoKLA" title="Elokuu 2021" />
            <YoutubeEmbed src="HKmWO8WYK-0" title="Kesäkuu 2021" />
            <YoutubeEmbed src="mo4s7aQy64k" title="Toukokuu 2021" />
            <YoutubeEmbed src="k4IPgxT5zHs" title="Huhtikuu 2021" />
            <YoutubeEmbed src="Sr-8gdOGB9Q" title="Maaliskuu 2021" />
            <YoutubeEmbed src="4MT0sC8YzPU" title="Helmikuu 2021" />
          </YoutubeGrid>
        </Section>

        <Section color="highlight" extraPaddingTop>
          <h2>#Koeaika koukutti ja aktivoi</h2>

          <p>
            Aiheen reaaliaikainen käsittely sosiaalisessa mediassa antoi
            yleisölle mahdollisuuden kurkistaa organisaatioiden arkeen pintaa
            syvemmältä ja osallistua tarinaan.
          </p>
          <p>
            Poikkeuksellinen kokeilu keräsi valtavan yleisön, ja sarjamainen
            lähestymistapa koukutti. #Koeaika näkyi Jaakon ja Duunitorin
            kanavissa: Duunitorin työnhakupalvelussa ja työelämämediassa,{" "}
            <a
              href="https://www.linkedin.com/feed/update/urn:li:share:6876491135485927424/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedInissä
            </a>
            ,{" "}
            <a
              href="https://www.instagram.com/p/CZHKcLOOH4o/"
              target="_blank"
              rel="noreferrer"
            >
              Instagramissa
            </a>
            , YouTubessa,{" "}
            <a
              href="https://twitter.com/NasimaRazmyar/status/1488157916242890760"
              target="_blank"
              rel="noreferrer"
            >
              Twitterissä
            </a>
            ,{" "}
            <a
              href="https://www.facebook.com/duunitori/posts/4990018711008678"
              target="_blank"
              rel="noreferrer"
            >
              Facebookissa
            </a>{" "}
            ja uutiskirjeissä videoiden, artikkelien, reaaliaikaisten storyjen
            ja postausten muodossa.
          </p>
          <small>* Linkeissä esimerkit orgaanisista julkaisuista.</small>
        </Section>
        <Section color="highlight">
          <WideImage src={montage} />
        </Section>
        <Section color="highlight">
          <p>
            Jatkuva vuorovaikutus oli olennainen osa sarjaa. Yleisö seurasi
            tutkimusmatkaa kiinnostuneena, kommentoi ahkerasti ja reagoi
            tunteikkaastikin. Sekä eri alojen osaajat että niiden ulkopuoliset
            kiittivät oivaltavia huomioita. #Koeaika keräsi merkittävän määrän
            reaktioita sosiaalisessa medissa, pääasiassa orgaanisilla jaoilla.
            Suurta osaa sisällöistä ei mainostettu ollenkaan.
          </p>
        </Section>
        <Section color="highlight">
          <WideImage src={feedback} mobileSrc={feedbackMobile} />
        </Section>

        <Section color="highlight" extraPaddingBottom>
          <KeyFigures
            contents={[
              ["10,6 M", "näyttökertaa"],
              ["2 M", "videoiden katselukertaa"],
              ["154 000", "artikkeleiden lukukertaa"],
              ["37 000", "reaktiota sosiaalisessa mediassa"],
              ["31", "mediamainintaa"],
              ["42 M", "potentiaalinen tavoittavuus ansaitulla medialla"],
              ["390 000 €", "ansaitun median arvo"],
              ["46,2 M", "koko projektin potentiaalinen tavoittavuus"],
              ["410 000 €", "potentiaalisen tavoittavuuden arvo"],
            ]}
          />

          <small>
            * Näyttö- ja lukukerrat eivät sisällä ansaittua mediaa, ja kaikki
            data ei ole vielä mukana, sillä viimeiset #Koeaika-kuukaudet ovat
            käynnissä. Potentiaalisessa tavoittavuudessa ei ole mukana projektin
            tärkeintä somekanavaa LinkedIniä, ja ansaitun median tavoittavuuden
            arviossa ovat mukana vain digitaaliset uutismediat. Potentiaalisen
            tavoittavuuden ja sen arvon on analysoinut Meltwater.
          </small>
        </Section>

        <Section extraPaddingTop>
          <h2>
            Media tarttui <Hash />
            Koeaikaan
          </h2>

          <p>
            <Hash />
            Koeaika-vuosi poiki huomattavasti ansaittua mediaa. Kattavia
            katsauksia julkaistiin Suomen suurimmissa valtakunnallisissa
            laatumedioissa, kuten Ylellä, Suomen Kuvalehdessä ja MTV:n
            tv-uutisissa. Myös paikallismediat ja eri alojen ammattilaisten
            mediat kiinnostuivat projektista.
          </p>
        </Section>
        <Section>
          <Boxes
            contents={[
              {
                src: media11,
                link: "https://www.vantaansanomat.fi/paikalliset/4448668",
                wide: "true",
                title:
                  "Vantaan päiväkotien arkea tarkkaileva psykologi Jaakko Sahimaa hämmästelee alan matalaa palkkausta ja arvostuksen puutetta – ”Tämä on outo paradoksi”",
                media: "Vantaan Sanomat",
              },
              {
                src: media12,
                link: "https://suomenkuvalehti.fi/jutut/kotimaa/mika-tekee-tyosta-tarkeaa-psykologi-paatti-selvittaa/",
                wide: "true",
                title:
                  "Mikä tekee työstä tärkeää? Psykologi päätti selvittää kokeilemalla 12 eri ammattia – ”Kyllä mukana on varmasti egoprojekteja”",
                media: "Suomen kuvalehti",
                mobile: true,
              },
              {
                src: media13,
                link: "https://yle.fi/aihe/artikkeli/2021/03/10/kun-tyo-tuntuu-merkitykselliselta-se-vaikuttaa-suoraan-hyvinvointiin-ja",
                wide: "true",
                title:
                  "Kun työ tuntuu merkitykselliseltä, se vaikuttaa suoraan hyvinvointiin ja terveyteen – omaa työtään voi tuunata mielekkäämmäksi",
                media: "Akuutti | Yle.fi",
                mobile: true,
              },
              {
                src: media14,
                link: "https://www.mtvuutiset.fi/artikkeli/psykologi-jaakko-28-kokeili-kuukauden-kolmivuorot[…]ota-yovuorojen-rankkuus-yllatti-arvostus-on-kasvanut/8080468",
                wide: "true",
                title:
                  "Psykologi Jaakko, 28, kokeili kuukauden kolmivuorotyötä – yövuorojen rankkuus yllätti: ”Arvostus on kasvanut”",
                media: "Mtv Uutiset",
              },
              {
                src: media21,
                link: "https://www.hrviesti.fi/natiivi/3107/tyoelaman-loytoretkeilija-vaihtaa-duunia-joka-kuukausi",
                title: "Työelämän löytöretkeilijä vaihtaa duunia joka kuukausi",
                media: "HR Viesti 2021",
                mobile: true,
              },
              {
                src: media22,
                link: "https://www.mtvuutiset.fi/artikkeli/psykologi-jaakko-kokeili-asiakaspalvelutyota-ja-yllattyi-antaa-nyt-nelja-vinkkia-asiakkaille/8167114#gs.ny5kw9",
                title:
                  "Psykologi Jaakko kokeili asiakaspalvelutyötä ja yllättyi – antaa nyt neljä vinkkiä asiakkaille",
                media: "Mtv Uutiset",
              },
              {
                src: media23,
                link: "https://issuu.com/myyntijamarkkinointi/docs/421mma_kevyt",
                title:
                  "Jaakko Sahimaa tutustuu #Koeaika-projektissaan työelämään tekemällä vuoden ajan 12:ta eri työtä 12:lla eri alalla",
                media: "Myynti ja markkinointi",
              },
              {
                src: media24,
                link: "https://www.iltalehti.fi/tyoelama/a/2eab7f4b-2f2c-4e01-a05f-a9979fd51a7c",
                title:
                  "Psykologi meni töihin kehitysvammaisten hoitajaksi – työ meni heti ihon alle",
                media: "Iltalehti",
                mobile: true,
              },
              {
                src: media25,
                link: "https://kaksplus.fi/perhe/kasvatus/psykologi-vietti-kuukauden-paivakodissa-varhaiskasvatusta-tutkien-mainettaan-parempaa-tyota/",
                title:
                  "Psykologi vietti kuukauden päiväkodissa varhaiskasvatusta tutkien: ”Mainettaan parempaa työtä”",
                media: "Kaksplus",
              },
              {
                src: media31,
                link: "https://www.ess.fi/paikalliset/3989859",
                wide: "true",
                title:
                  "”En mene kukkopoikana neuvomaan” – työpsykologi lähti vuodeksi kokeilemaan muiden töitä, luvassa haalarihommia ja kattojen myyntiä",
                media: "Etelä-Suomen Sanomat",
              },
              {
                src: media32,
                link: "https://www.is.fi/menaiset/tyo-ja-raha/art-2000007857307.html",
                wide: "true",
                title:
                  "Psykologi kokeili vuorotyötä kuukauden ja huomasi heti epäsäännöllisten työaikojen vaikutukset kehossa",
                media: "Me Naiset",
              },
              {
                src: media33,
                link: "https://www.helsinginuutiset.fi/paikalliset/4453207",
                wide: "true",
                title:
                  "Psykologi Jaakko Sahimaa, 29, kiertää vuoden aikana 12 eri työpaikassa – Päiväkodissa hän hämmentyi: ”Tämä on outo paradoksi”",
                media: "Helsingin uutiet",
              },
              {
                src: media34,
                link: "https://www.mtvuutiset.fi/artikkeli/jaakko-vaihtaa-tyopaikkaa-kerran-kuussa-rehellisy[…]imissa-enemman-on-tullut-toppailtya-kuin-onnistuttua/8243206",
                wide: "true",
                title:
                  "Jaakko vaihtaa työpaikkaa kerran kuussa: ”Rehellisyyden nimissä enemmän on tullut töppäiltyä kuin onnistuttua”",
                media: "Mtv Uutiset",
                mobile: true,
              },
            ]}
          />
        </Section>

        <Section>
          <Lede small>
            <Hash />
            Koeaika vei rekrytointimarkkinoinnin ja työnantajakuvan rakentamisen
            uudelle tasolle. Asiakkaat saivat viestiä tärkeästä työstään
            jättiyleisölle ja toisaalta tavoittivat itselleen olennaiset
            osaajaryhmät.
          </Lede>
        </Section>
        <Section>
          <Quotes
            contents={[
              {
                text: "Projektin omalaatuisuus herätti yllättävän suurta kiinnostusta sisäisesti digialaisissa, ja ilahduimme siitä ylpeydestä, jota osoitettiin omaa työtä, työkavereita ja työnantajaa kohtaan. Joskus ulkopuoliselle on helpompi kertoa, kuinka siistejä juttuja pääsee tekemään joka arkipäivä. Oli hienoa edustaa it-alaa Koeaika-projektissa ja kertoa Digiasta laajalle joukolle Jaakon ja Duunitorin verkostojen kautta. Organisaatiopsykologina Jaakko teki loistavia havaintoja toimintakulttuuristamme.",
                source: "Merita Olkkonen, Talent Acquisition Lead, Digia Plc",
                narrow: true,
              },

              {
                text: "#Koeaika oli innovatiivinen ja monipuolinen, paljon kiinnostusta herättävä konsepti, joka oli toteutettu erittäin taidokkaasti ja huolella. Täydet pisteet!",
                source: "Isa Merikallio, sisältöjohtaja, Eezy Oyj",
                narrow: true,
              },

              {
                text: "Toimintamme monipuolisuus ja osaajiemme omistautuminen työllensä näyttäytyi aidolla ja välittömällä tavalla.",
                source: "Mari Rahikka, viestintäpäällikkö, YIT",
                narrow: true,
              },
              {
                text: "#Koeaika tuntui hienolta mahdollisuudelta osallistua aivan uudenlaiseen työnantajakuvan rakentamiseen. Projekti perustui aitouteen; Jaakko pääsi todella näkemään, millaista työ DNA:lla on. Siksi tämä vaati osallistujilta rehellisyyttä ja oli varmasti kaikille osallistuville organisaatiolle hyvä tulikoe; mitä mieltä ulkopuolinen todella on työkulttuurista? Tämä sopi meille DNA:lla hyvin, sillä pyrimme noudattamaan filosofiaa, jonka mukaan ”paskaa ei voi kuorruttaa”. Kotipesän pitää siis olla kunnossa. Hanke vahvisti työnantajakuvaamme niin sisäisesti kuin ulkoisestikin.",
                source: "Ville Halonen, viestintäpäällikkö, DNA Oyj",
              },
              {
                text: "Erityisen arvokasta #Koeaika-hankkeessa oli, että sen avulla voidaan päivittää mielikuvia opettajan työstä nykyaikaan. Jaakko näki, koki ja kertoi juuri sellaisia asioita, joita opettajat itsekin pitävät työssään tärkeinä. Hän osasi hienosti sanoittaa sellaisiakin puolia opettajan työstä, joita moni alan ulkopuolinen ei ole tullut ajatelleeksi.",
                source:
                  "Heikki Pölönen, viestintäpäällikkö, Opetusalan Ammattijärjestö OAJ",
                narrow: true,
              },
              {
                text: "Arvokasta oli erityisesti se, että työn arki pääsi esille, suodattamattomana. Avoimuus ja aitous toimii. Kampanja on myös rohkaissut omaa henkilökuntaa jakamaan työpäivän sisältöjä somelähettiläinä.",
                source: "Taina Rönnqvist, viestintäpäällikkö, Rinnekoti",
                narrow: true,
              },
            ]}
          />
        </Section>

        <Section>
          <NarrowImage src={logos} mobileSrc={logosMobile} />
        </Section>

        <Section mobileCenter>
          <h2>Duunitorin tiimi</h2>
          <Team>
            Aino Salonen <br />
            Anna Mäkelä <br />
            Fredrik Bäckman <br />
            Heta Pärssinen <br />
            Janita Lindström <br />
            Johanna Savinen <br />
            Lauri Iso-Markku <br />
            Lotta Jussila <br />
            Meri-Tuuli Talsi <br />
            Otto Martikainen <br />
            Petri Hänninen <br />
            Saara Holma <br />
            Thomas Grönholm <br />
            Tommi Haapaniemi <br />
            Veera Korpela
          </Team>
          <p>
            <a href="https://koeaika.fi" target="_blank" rel="noreferrer">
              koeaika.fi
            </a>
          </p>
        </Section>
      </main>
    </>
  );
};

export default Main;
