import styled from "styled-components";

import quote from "../imgs/quote.svg";
import quoteWhite from "../imgs/quote-white.svg";

const Source = styled.p`
  font-size: 0.8em;
  margin-top: -1rem;
  margin-bottom: 0;
`;

const QuoteWrapper = styled.div`
  position: relative;
  padding-top: 0.1rem;
  margin-bottom: 3rem;

  &:before {
    content: "";
    ${(props) =>
      props.color === "white"
        ? `background-image: url(${quoteWhite})`
        : `background-image: url(${quote})`};
    width: 1rem;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0.3rem;

    font-size: 2em;
    font-weight: bold;
  }

  @media (min-width: 680px) {
    ${(props) =>
      props.narrow
        ? `
    width: calc(50% - 4rem);
    float: left;
    margin-right: 4rem;
    `
        : `clear: both;`}
  }
`;

const Quote = (props) => (
  <QuoteWrapper narrow={props.narrow} {...props}>
    {props.children}
    <Source>{props.source}</Source>
  </QuoteWrapper>
);

export default Quote;
