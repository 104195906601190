import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: space-between;

  position: relative;
  left: 50%;
  right: 50%;
  margin-left: calc(var(--full-width-vw) / -2);
  margin-right: calc(var(--full-width-vw) / -2);
  max-width: var(--full-width-vw);
  width: var(--full-width-vw);
  flex-wrap: wrap;
`;

const boxStyles = (props) => `
  flex: 0 0 calc(${props?.wide ? "25" : "20"}% - 1.5rem);
  margin-bottom: 1.5rem;

  @media (max-width: 680px) {
    ${
      props?.mobile
        ? props?.wide
          ? "flex-basis: calc(100%); justify-self: middle;"
          : "flex-basis: calc(50% - 1rem);"
        : "display: none;"
    }
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const Div = styled.div`
  ${(props) => boxStyles(props)}
`;
const A = styled.a`
  ${(props) => boxStyles(props)}
`;

const Boxes = (props) => (
  <Wrapper {...props}>
    {props?.contents?.map(({ src, link, wide, title, media, mobile }) => {
      const El = link ? A : Div;

      return (
        <El
          href={link}
          wide={wide}
          mobile={mobile}
          target="_blank"
          rel="noreferrer"
        >
          <Img src={src} alt={`${media}: ${title}`} />
        </El>
      );
    })}
  </Wrapper>
);

export default Boxes;
